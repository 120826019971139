export default class Accordion {
  constructor(element) {
    this.element = element;
    this.accordionContainers = document.querySelectorAll('.js-header');
    this.init();
  }

  init() {
    for (let i = 0; i < this.accordionContainers.length; i++) {
      this.accordionContainer = this.accordionContainers[i];
      this.accordionContainer.addEventListener(
        'click',
        this.toggleActive.bind(this)
      );
      this.autoOpen();
    }
  }

  toggleActive(event) {
    if ('notClosing' in this.element.dataset) {
      event.currentTarget.classList.toggle('is-active');
    } else {
      for (let i = 0; i < this.accordionContainers.length; i++) {
        const accordionContainer = this.accordionContainers[i];

        if (accordionContainer != event.currentTarget) {
          accordionContainer.classList.remove('is-active');
        }
      }
      event.currentTarget.classList.toggle('is-active');
    }
  }

  autoOpen() {
    if ('autoOpen' in this.accordionContainer.dataset) {
      this.accordionContainer.classList.add('is-active');
    }
  }
}
