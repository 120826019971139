import Swiper from 'swiper/bundle';

export default class Carousel {
  constructor(element) {
    this.element = element;
    this.options = {
      slidesPerView: 1.5,
      spaceBetween: 20,
    };
    this.init();
  }

  setOptions() {
    if ('hero' in this.element.dataset) {
      this.options = {
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
          el: this.element.querySelector('.swiper-pagination'),
          type: 'bullets',
        },
      };
    }

    if ('split' in this.element.dataset) {
      this.options.breakpoints = {
        768: {
          slidesPerView: 3.5,
        },
        480: {
          slidesPerView: 2.5,
        },
      };
    }
  }

  init() {
    this.setOptions();
    const swiper = new Swiper(this.element, this.options);
  }
}
